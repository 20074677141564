@use "../../../styles/mixins" as *;
@include layer(ui) {
    .dot {
        height: 8px;
        width: 8px;
        border: 1.5px solid var(--border-color-default);
        border-radius: 100%;
        background-color: var(--background-default);
        transition: none var(--g-duration-xshort) ease;
        transition-property: background-color, border-color;
        cursor: pointer;
        padding: 0px;
        margin: 0 0 0 var(--g-spacing-sm);
        appearance: none;

        &:first-child {
            margin: 0;
        }

        &:active:not(:disabled),
        &:hover:not(:disabled) {
            border-color: var(--border-color-active);
            background-color: var(--background-active);
        }

        &:focus-visible:not(:disabled) {
            border-color: var(--border-color-focus);
            background-color: var(--background-focus);
            transition: none;
            outline: solid var(--g-border-width-sm) var(--outline-color-focus);
            outline-offset: var(--g-spacing-xxs);
        }

        &:disabled:not([data-selected="true"]) {
            cursor: not-allowed;
            border-color: var(--border-color-disabled-default);
            background-color: var(--background-disabled-default);

            @media (forced-colors: active) {
                background-color: GrayText;
            }
        }

        &:disabled {
            cursor: not-allowed;
            border-color: var(--border-color-disabled-selected);
            background-color: var(--background-disabled-selected);
        }

        &[data-selected="true"]:not(:disabled) {
            border-color: var(--border-color-selected);
            background-color: var(--background-selected);

            @media (forced-colors: active) {
                background-color: Highlight;
            }
        }

        &--inverse {
            opacity: var(--g-opacity-moderate);

            &:active:not(:disabled),
            &:hover:not(:disabled) {
                opacity: var(--g-opacity-moderate);
            }

            &:focus-visible:not(:disabled) {
                opacity: 1;
            }

            &:disabled:not([data-selected="true"]) {
                opacity: 1;
            }

            &:disabled {
                opacity: 1;
            }

            &[data-selected="true"]:not(:disabled) {
                opacity: 1;
            }
        }
    }
}
