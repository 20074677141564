@use "@wojo/ui/mixins" as *;

@mixin nav-background {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--g-color-black);
    opacity: 0.6;
    z-index: -1;
    border-radius: 32px;
}

@include layer(storyblok) {
    .wrapper {
        position: absolute;
        top: 50%;
        right: var(--g-spacing-md);
        z-index: 1;
    }

    .slider {
        display: flex;
        position: relative;
        padding: var(--g-spacing-sm);
        margin: var(--g-spacing-sm) 0;
        justify-content: center;

        &--over-max-items {
            height: 70px;
            overflow: hidden;
        }

        &::before {
            @include nav-background;
        }
    }

    .track {
        display: flex;
        flex-direction: column;
        gap: var(--g-spacing-sm);
        align-items: center;
        justify-content: flex-start;
    }

    .indicator-wrapper {
        display: flex;
        min-height: var(--g-spacing-sm);
        min-width: var(--g-spacing-sm);
        align-items: center;
        justify-content: center;
    }

    .indicator {
        height: var(--g-spacing-sm);
        display: flex;
        flex-shrink: 0;
        width: var(--g-spacing-sm);
        border: 1.5px solid var(--g-color-white);
        border-radius: 100%;
        background-color: transparent;
        transition: none var(--g-duration-xshort) ease;
        transition-property: background-color, border-color;
        cursor: pointer;
        padding: 0px;
        margin: 0px;
        appearance: none;
        opacity: 0.7;

        &[data-selected="true"]:not(:disabled) {
            border-color: var(--g-color-white);
            background-color: var(--g-color-white);
            opacity: 1;

            @media (forced-colors: active) {
                background-color: Highlight;
            }
        }

        &:active:not(:disabled),
        &:hover:not(:disabled) {
            opacity: var(--g-opacity-moderate);
            border-color: var(--g-color-white);
            background-color: var(--g-color-white);
        }

        &:focus-visible:not(:disabled) {
            border-color: var(--g-color-white);
            background-color: var(--g-color-white);
            outline: solid var(--g-border-width-sm) var(--g-color-white);
            outline-offset: var(--g-spacing-xxs);
            opacity: 1;
        }

        &:disabled:not([data-selected="true"]) {
            cursor: not-allowed;
            border-color: var(--s-color-disabled-primary);

            @media (forced-colors: active) {
                background-color: GrayText;
            }
        }

        &:disabled[data-selected="true"] {
            cursor: not-allowed;
            border-color: var(--s-color-disabled-primary);
            background-color: var(--s-color-disabled-primary);
        }
    }

    .button {
        width: var(--g-spacing-lg);
        height: var(--g-spacing-lg);
        position: relative;

        &::before {
            @include nav-background;
        }

        &--visually-hidden:not(:focus-visible) {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            white-space: nowrap;
            width: 1px;
        }
    }
}
