@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .preview-container {
        padding-bottom: var(--g-spacing-xl);

        @include breakpoint-md {
            display: none;
        }
    }

    .preview {
        position: relative;
        aspect-ratio: var(--aspect-ratio, auto);
        display: flex;
    }

    .video-wrapper {
        position: absolute;
        top: 0;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translateX(-50%);
        transition: transform var(--g-duration-short) ease-in-out;
        transform-origin: bottom;
    }

    .thumbnail {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .click-to-play-area {
        background: none;
        border: none;
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 10;

        &:hover .play-button {
            color: var(--g-color-primary-500);
        }

        &:focus-visible {
            /* outline: none here is okay because .play-button has the outline */
            outline: none;
        }

        &:focus-visible .play-button {
            color: var(--g-color-primary-500);
            box-shadow: (var(--s-shadow-standard-active));
            outline-color: var(--outline-color-focus);
            outline-offset: calc(var(--g-spacing-xs) * -1);
            outline-style: solid;
            outline-width: var(--g-border-width-sm);
            transition: none;
        }

        &:active .play-button {
            transform: scale(0.96);
        }
    }

    .play-button {
        --button-height: var(--g-size-md);
        --button-width: var(--g-size-md);
        align-items: center;
        background: var(--g-color-white);
        border-radius: 100%;
        color: var(--g-color-ink-black);
        display: flex;
        box-shadow: (var(--s-shadow-standard-default));
        height: var(--button-height);
        justify-content: center;
        left: calc(50% - calc(var(--button-width) / 2));
        position: absolute;
        top: calc(50% - calc(var(--button-height) / 2));
        transition: none var(--g-duration-xshort) ease;
        transition-property: color, filter;
        width: var(--button-width);
        z-index: 10;

        @include for-tablet-up {
            --button-height: var(--g-size-lg);
            --button-width: var(--g-size-lg);
        }

        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }

        svg {
            height: 24px;
            width: 24px;

            @include for-tablet-up {
                height: 30px;
                width: 30px;
            }
        }
    }
}
